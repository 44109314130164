label {
  display: block;
}

li {
  margin-bottom: 1rem;
}

.text-small-caps {
  font-variant: small-caps;
}

button:focus {
  outline: none;
}

@media (width >= 768px) {
  .position-md-fixed {
    position: fixed;
  }
}

.section {
  margin-bottom: 3rem;
}

[v-cloak] {
  display: none;
}
/*# sourceMappingURL=index.8947ce8e.css.map */
